import { VuetifyThemeVariant } from "vuetify/types/services/theme";

const light: Partial<VuetifyThemeVariant> = {
  "primary": "#1542a8",
  "secondary": "#ffcc00",
  "tertiary": "#ffcc00",
  "dark-background": "#1542a8",
  "background": "#eee",
  "content-background": "#F8F8F8",
  "heading": "#fff",
  "body": "#D4D4D4",
};

export default { light };
