import Coupon from "@/types/Coupon";
import Sector from "@/types/Sector";

export type CouponsUsage = Record<string, Array<{ coupon: Coupon; uses: number }>>;

export class CouponHelper {
  // Make sure to reset this on cart reset and when the user changes the coupons
  static associations: CouponsUsage = {};

  static apply(amount: number, coupon: Coupon): number {
    if (coupon.method === "percentage") {
      const percentage = (100 - coupon.value);

      return amount - (amount * percentage) / 100;
    }

    return amount - coupon.value / 100;
  }

  static calculate(amount: number, coupon: Coupon): number {
    if (coupon.method === "percentage") {
      return (amount * coupon.value) / 100;
    }

    return coupon.value / 100;
  }

  static associate(coupons: Coupon[], sectors: Sector[]) {
    const types = sectors.flatMap(s => s.sections.flatMap(s => s.tipos_ingresso)).sort((a, b) => parseFloat(b.preco) - parseFloat(a.preco));

    // Sort by order that they should be applied: absolute first and then percentage
    const _coupons = coupons.sort((a, b) => a.method.localeCompare(b.method));

    // Associate types with coupons
    CouponHelper.associations = types.reduce((acc, type) => ({ ...acc, [type.id]: [] }), {})

    // Keeps track of max_items of each coupon
    const usesLeft = _coupons.reduce((acc, coupon) => ({ ...acc, [coupon.code]: coupon.max_items }), {}) as Record<string, number>;

    // Do the thing
     types.forEach(type => {
      const coupons = _coupons.filter(coupon => usesLeft[coupon.code] > 0 && (coupon.ticket_types.length === 0 || coupon.ticket_types.some(t => t.id === type.id)));

      coupons.forEach(coupon => {
        const remainder = usesLeft[coupon.code] - type.quantidade;
        const used = remainder >= 0 ? type.quantidade : (type.quantidade + remainder);
 
        CouponHelper.associations[type.id].push({ coupon: coupon, uses: used });

        usesLeft[coupon.code] = Math.max(0, remainder);
      });
    });
  }
}
