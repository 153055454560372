import { configuration } from "@/lang/pt_BR";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "pt-br",
  messages: configuration.default,
});

export { i18n };
