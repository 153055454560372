import { themes } from "@/assets/themes";
import { configuration } from "@/lang/pt_BR";
import { WhitelabelProducer } from "@/types/Producer";

export const producers: WhitelabelProducer[] = [
  {
    id: "0",
    slug: "",
    domain: "ingressodevantagens.com.br",
    title: "Ingresso de Vantagens",
    footer: "Uma empresa do Grupo Dataclick",
    theme: themes.base,
    locale: configuration.default,
    analytics: [
      "G-3FRE63HPHD",
      "230989696734366",
    ],
  },
  {
    id: "f22a4430-c4d9-11ec-b892-ef25fe858e6b",
    domain: "jec.ingressodevantagens.com.br",
    slug: "jec-futsal",
    title: "JEC Krona",
    footer: "Uma empresa do Grupo Dataclick",
    theme: themes.jec,
    locale: configuration.default,
  },
  {
    id: "c0a43340-b476-11ee-901b-bd30cdc86c8d",
    domain: "barra.ingressodevantagens.com.br",
    slug: "barra",
    title: "Barra FC",
    footer: "Uma empresa do Grupo Dataclick",
    theme: themes.barra,
    locale: configuration.default,
    analytics: [
      "218154797957065",
    ],
  },
  {
    id: "0de57220-b12b-11e8-8ee6-5f76827d02a6",
    domain: "dataclick.ingressodevantagens.com.br",
    slug: "dataclick",
    title: "DTKSports",
    footer: "Uma empresa do Grupo Dataclick",
    theme: themes.base,
    locale: configuration.default,
    analytics: [
      "230989696734366",
    ],
  },
  {
    id: "93b39f20-da54-11ef-b2d0-c7b4af5ee45e",
    domain: "itajai.ingressodevantagens.com.br",
    slug: "itajai",
    title: "Prefeitura de Itajaí",
    footer: "Secretaria Municipal de Tecnologia",
    theme: themes.itajai,
    locale: configuration.default,
    analytics: [
      "1270048361091568",
    ],
  },
  {
    id: "bdabcc90-f3b2-11ef-8042-df79c9b6e72d",
    domain: "lounge.maracanaclub.com.br",
    slug: "maracana",
    title: "Maracanã Club",
    footer: "Powered by Ingresso de Vantagens",
    theme: themes.maracana,
    locale: configuration.maracana,
    analytics: [
      "G-4GBBZ43J9F",
    ],
  },
];

export const initial = producers[0];
