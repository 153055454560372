<template>
  <div>
    <t-section-header header="Informações" icon="mdi-information-outline" />
    <v-footer color="dark-background" dark class="pt-4">
      <t-container>
        <v-row justify="space-between">
          <v-col
            v-for="item in items"
            :key="item.title"
            cols="12"
            sm="6"
            lg="auto"
            class="d-flex flex-column body--text"
            :class="{ 'align-center': isMobile }"
          >
            <span
              cols="auto"
              class="footer-title-text font-weight-medium text-wrap mb-2 text-uppercase"
              style="overflow-wrap: break-word; color: white !important"
            >
              {{ item.title }}
            </span>

            <template v-for="link in item.links">
              <router-link
                v-if="link.to"
                :key="link.to || link.href"
                :to="link.to"
                class="footer-item-text text-decoration-none body--text"
                style="border-bottom: 1px solid; padding: 0.1rem; margin-bottom: 0.4rem"
              >
                {{ link.label }}
              </router-link>

              <a
                v-else
                :key="link.to || link.href"
                :href="link.href"
                target="_blank"
                class="footer-item-text text-decoration-none body--text"
                style="border-bottom: 1px solid; padding: 0.1rem"
              >
                {{ link.label }}
              </a>
            </template>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            lg="4"
            class="d-flex align-center flex-column my-3 py-0 px-0"
          >
            <t-logo-footer dark :style="{'width': isMobile ? '160px' : '200px'}" class="mt-2" />
            <v-row>
              <v-col
                :cols="isMobile ? '12' : 'auto'"
                :class="{'d-flex': true, 'align-center': true, 'justify-center': true, 'mt-5': isMobile, 'mt-0': !isMobile }"
              >
                <small style="color: unset; font-size: 11px;">
                  {{ producer.footer }}
                </small>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Informações Mobile -->
        <template v-if="isMobile">
          <v-row>
            <v-col cols="12" class="text-center pb-0">
              <small style="font-size: 11px"> Ingresso de Vantagens Ltda | CNPJ: 51.781.190/0001-62 </small>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-6 text-caption text-center" no-gutters>
            <v-col cols="auto">
              Copyright © {{ year }} {{ producer.title }}
            </v-col>

            <v-col cols="12">
              v{{ $store.state.app.version }} ({{ $store.state.app.commit }})
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" class="text-center pb-0">
              <small>
                Ingresso de Vantagens Ltda | CNPJ: 51.781.190/0001-62
              </small>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-6 text-caption text-center" no-gutters>
            <v-col cols="auto">
              Copyright © {{ year }} {{ producer.title }} ( v{{ $store.state.app.version }} -
              {{ $store.state.app.commit }})
            </v-col>
          </v-row>
        </template>
        <!-- Informações Desktop -->
      </t-container>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Vue from "vue";

import TLogoFooter from "./TLogoFooter.vue";
import TSectionHeader from "./TSectionHeader.vue";
import { WhitelabelProducer } from "@/types/Producer";

export default Vue.extend({
  name: "TheFooter",

  components: {
    TLogoFooter,
    TSectionHeader,
  },

  data: () => ({
    items: [
      {
        title: "Institucional",
        links: [
          {
            label: "Quem Somos",
            to: "/sobre",
          },
          {
            label: "Termos de Uso",
            to: "/termos",
          },
        ],
      },

      {
        title: "Nossas Políticas",
        links: [
          {
            label: "De Privacidade",
            to: "/privacidade",
          },
          {
            label: "De Cancelamento",
            to: "/cancelamentos",
          },
          {
            label: "De Meia-entrada",
            to: "/meia-entrada",
          },
        ],
      },

      {
        title: "Contato",
        links: [
          {
            label: "Fale Conosco",
            to: "/suporte",
          },
          {
            label: "Dúvidas Frequentes",
            to: "/faq",
          },
        ],
      },
    ],

    whatsAppColor: "#25d366",
  }),

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    year() {
      return DateTime.now().year;
    },
    producer(): WhitelabelProducer {
      return this.$store.state.producers.selected as WhitelabelProducer;
    },
  },
});
</script>

<style scoped>
strong {
  font-size: 1.5rem;
  font-weight: 400;
}
.footer__container {
  text-align: left;
  font-weight: 300;
  font-size: 0.9rem;
}
.whatsapp__button {
  background-color: #25d366;
}
.whatsapp__button sub {
  font-size: 1.2rem;
  position: relative;
  top: 2px;
}

.footer-title-text {
  font-size: 16px;
}

.footer-item-text {
  font-size: 14px;
}

.copyright-row {
  background: black;
}

.v-footer {
  padding: 0;
}
</style>
