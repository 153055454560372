import CreditCardPayment from "@/types/CreditCardPayment";
import NominalTicket from "@/types/NominalTicket";
import Order from "@/types/Order";
import PixPayment from "@/types/PixPayment";
import Reservation from "@/types/Reservation";
import Sector from "@/types/Sector";
import { RootState } from "..";
import cpf from "@/filters/cpf";
import { DateTime } from "luxon";
import SeatWithTicketType from "@/types/Mapping/SeatWithTicketType";
import { ActionContext, GetterTree } from "vuex";
import reserveTickets from "@/api/requests/reserveTickets";
import Coupon from "@/types/Coupon";
import { CouponHelper, type CouponsUsage } from "@/utils/coupons";
import TicketType from "@/types/TicketType";
import Fee from "@/utils/fees";

type NominalTickets = Record<string, (NominalTicket & { seat?: string })[]>;

export interface State {
  sectors: Sector[];
  control: {
    step: number;
  };
  reservation: Reservation;
  order: Order;
  payment: PixPayment | CreditCardPayment | null;
  tickets: NominalTickets;
  coupons: Coupon[];
  couponAssociations: CouponsUsage;
  event_id: string | null;
  timer: {
    isRunning: boolean;
    time?: string;
  };
}

export interface Getters {
  total: (state: State, getters: Getters) => number;
  subtotalForType: (state: State) => (type: TicketType) => number;
}

const state: State = {
  sectors: [] as Sector[],
  control: {
    step: 1,
  },
  reservation: {} as Reservation,
  order: {} as Order,
  payment: null,
  tickets: {} as NominalTickets,
  coupons: [] as Coupon[],
  couponAssociations: {} as CouponsUsage,
  event_id: null,
  timer: {
    isRunning: false,
    time: undefined,
  },
};

const mutations = {
  reset(state: State): void {
    state.sectors = [];
    state.control.step = 1;
    state.reservation = {} as Reservation;
    state.order = {} as Order;
    state.payment = null;
    state.tickets = {} as NominalTickets;
    state.coupons = [];
    state.timer = {
      isRunning: false,
      time: undefined,
    };
    CouponHelper.associations = {};
  },
  setSectors(state: State, sectors: Sector[]): void {
    const temp = state.sectors;

    for (const sector of sectors) {
      const tempSector = temp.find(s => s.id == sector.id);
      if (!tempSector) continue;

      for (const section of sector.sections) {
        const tempSection = tempSector.sections.find(s => s.secao_id == section.secao_id);
        if (!tempSection) continue;

        for (const type of section.tipos_ingresso) {
          const tempType = tempSection.tipos_ingresso.find(t => t.id == type.id);

          if (!tempType) continue;

          type.quantidade = tempType.quantidade;
        }
      }
    }

    state.sectors = sectors;
  },
  setStep(state: State, value: number): void {
    state.control.step = value;
  },
  startTimer(state: State): void {
    state.timer = {
      isRunning: true,
      time: DateTime.now().toISOTime()!,
    };
  },
  resetTimer(state: State): void {
    state.timer = {
      isRunning: false,
      time: undefined,
    };
  },
  clearCart(state: State): void {
    state.sectors.forEach((sector) => {
      sector.sections.forEach((sections) => {
        sections.tipos_ingresso.forEach((type) => {
          type.quantidade = 0;
        });
      });
    });
    state.coupons = [];
    CouponHelper.associations = {};
  },
  // PRECISA ALTERAR ESSA PORRA POR VALOR PUTA QUE PARIU EU ODEIO JAVASCRIPT

  alterTicketTypeQuantity(
    state: State,
    { sector_id, section_id, type_id, delta }: { sector_id: string; section_id: string; type_id: string; delta: number },
  ): void {
    state.sectors = state.sectors.map((_sector) => {
      if (_sector.id !== sector_id) return _sector;

      _sector.sections = _sector.sections.map((_section) => {
        if (_section.secao_id !== section_id) return _section;

        _section.tipos_ingresso = _section.tipos_ingresso.map((_type) => {
          if (_type.id !== type_id) return _type;

          _type.quantidade = Math.max(0, _type.quantidade + delta);
          return _type;
        });

        return _section;
      });

      return _sector;
    });
  },
  setReserva(state: State, reservation: Reservation): void {
    state.reservation = reservation;
  },
  setPayment(state: State, payment: PixPayment | CreditCardPayment): void {
    state.payment = payment;
  },
  setEventId(state: State, id: string | null): void {
    state.event_id = id;
  },
  setCoupons(state: State, coupons: Coupon[]): void {
    // Sort coupons by method: absolute first and then percentage
    state.coupons = coupons.sort((a, b) => a.method.localeCompare(b.method));
    CouponHelper.associate(state.coupons, state.sectors);
    state.couponAssociations = CouponHelper.associations;
  },
  removeCoupon(state: State, coupon: Coupon): void {
    state.coupons = state.coupons.filter(c => c.id !== coupon.id);
    CouponHelper.associate(state.coupons, state.sectors);
  },
};

const getters: GetterTree<State, RootState> = {
  total: (state: State, getters: any, rootState: RootState): number => {
    let total = 0;

    for (const sector of state.sectors) {
      for (const section of sector.sections) {
        const types = section.tipos_ingresso.filter(t => t.quantidade > 0);
        total += types.reduce((acc, type) => {
          return acc + getters.subtotalForType(type);
        }, 0);
      }
    }

    return Fee.apply(total, rootState.events.selected.convenience_fee, rootState.events.selected.convenience_fee_mode);
  },
  maxInstallments: (state: State): number => {
    let max = 1;

    for (const sector of state.sectors) {
      for (const section of sector.sections) {
        const types = section.tipos_ingresso.filter(t => t.quantidade > 0);
        console.log(...types.map(t => t.max_installments));
        max = Math.max(
          Math.max(...types.map(t => t.max_installments)),
          max,
        );
      }
    }

    return max;
  },
  subtotalForType: (state: State) => (type: TicketType) => {
    const value = parseFloat(type.preco);
    const items: number[] = Array(type.quantidade).fill(value) as number[];
    const coupons = state.coupons.length == 0 ? [] : CouponHelper.associations[type.id];

    if (coupons && coupons.length > 0) {
      for (const coupon of coupons) {
        for (let i = 0; i < coupon.uses; i++) {
          if (items.length < i) break;

          items[i] -= CouponHelper.apply(items[i], coupon.coupon);
        }
      }
    }

    return items.reduce((acc, i) => acc + i);
  },
  selectedTicketTypes: (state: State) => {
    const types: TicketType[] = [];
    for (const sector of state.sectors) {
      for (const section of sector.sections) {
        types.push(...section.tipos_ingresso.filter(t => t.quantidade > 0));
      }
    }
    return types;
  },
};

const actions = {
  generateNominalTickets(
    { state, rootState }: { state: State; rootState: RootState },
    payload: { tickets: SeatWithTicketType[] },
  ): void {
    state.tickets = {};
    // Ensures that only the first ticket will have the name and document filled
    let firstTicket = true;

    for (const item of state.reservation.items) {
      const sector = state.sectors.find(s => s.id === item.sector_id);
      if (sector === undefined) return;

      const section = sector.sections.find(s => s.secao_id == item.section_id);
      if (section === undefined) return;

      const type = section.tipos_ingresso.find(t => t.id == item.ticket_type_id);
      if (type === undefined) return;

      if (payload.tickets) {
        const tickets = payload.tickets.filter(t => t.type.id === type.id);

        tickets.forEach((t) => {
          if (state.tickets[sector.id] === undefined) {
            state.tickets[sector.id] = [];
          }

          state.tickets[sector.id].push({
            name: t.nominal.name,
            cpf: cpf(t.nominal.cpf!),
            type: type.nome,
            preco: type.preco,
            typeId: type.id,
            seat: `${t.seat.symbol}${t.seat.number}`,
            additional: t.nominal.additional,
          });
        });
      } else {
        for (let i = 0; i < item.amount; i++) {
          if (state.tickets[sector.id] === undefined) {
            state.tickets[sector.id] = [];
          }

          state.tickets[sector.id].push({
            name: firstTicket ? rootState.user.data.name : null,
            cpf: firstTicket ? cpf(rootState.user.data.document) : null,
            email: firstTicket ? rootState.user.data.email : undefined,
            cellphone: firstTicket ? rootState.user.data.cellphone : undefined,
            type: type.nome,
            preco: type.preco,
            typeId: type.id,
            additional: type.additional_fields.map(a => ({ field: a, value: "" })),
          });
        }

        if (firstTicket) {
          firstTicket = false;
        }
      }
    }
  },
  updateTicketTypeAmountFromMapping(
    context: ActionContext<State, RootState>,
    { tickets, sector_id }: { tickets: SeatWithTicketType[]; sector_id: string },
  ): void {
    const types = Object.groupBy(tickets, ticket => ticket.type.id);

    // Complex stupid way to ensure that types with passport amount != 1 aren't
    // being counted multiple types
    for (const [_, value] of Object.entries(types)) {
      if (value === undefined) return;

      const items = value?.length;
      const passport_amount = value[0].type.passport_amount;

      for (let i = 0; i < items / passport_amount; i++) {
        context.commit("alterTicketTypeQuantity", {
          sector_id: sector_id,
          section_id: value[0].seat.section_id,
          type_id: value[0].type.id,
          delta: 1,
        });
      }
    }
  },
  async createReservation(
    context: ActionContext<State, RootState>,
    { event_id, token, seats }: { event_id: string; token: string; seats?: SeatWithTicketType[] },
  ): Promise<void> {
    const response = await reserveTickets({
      event_id: event_id,
      sectors: context.state.sectors,
      token,
      seats,
    });

    context.commit("setReserva", response);
    context.dispatch("generateNominalTickets", { tickets: seats });
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
