<template>
  <t-card max-width="600">
    <v-card-title>
      <span v-if="dialog.hasTitle">CRIAR CONTA</span>
      <v-spacer />
      <v-btn v-if="dialog.hasCloseButton" icon>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" class="my-4">
        <v-col cols="6">
          <t-logo stretch />
        </v-col>
      </v-row>
      <form-verify-user v-if="!verified" v-model="user" @verified="onVerifyUser($event)" />
      <form-new-user
        v-else
        ref="form"
        v-model="user"
        :loading="loading"
        @submit="createUser"
        @cancel="verified = false"
      />
    </v-card-text>
  </t-card>
</template>

<script lang="ts">
import Vue from "vue";

import User from "@/types/User";

import FormNewUser from "@/components/User/FormNewUser.vue";
import FormVerifyUser from "@/components/User/FormVerifyUser.vue";
import TCard from "@/components/core/TCard.vue";
import TLogo from "@/components/core/TLogo.vue";
import userCreate from "@/api/requests/userCreate";

import { DialogConfig } from "@/store/modules/dialog";
import Address from "@/types/Address";
import getAccessToken from "@/api/requests/getAccessToken";
import getUserData from "@/api/requests/getUserData";
import { DateTime } from "luxon";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";
import { TrackableEvent } from "@/analytics/TrackableEvents";

type ResetableForm = Vue & { reset: () => undefined };

export default Vue.extend({
  name: "UserRegister",

  components: {
    FormNewUser,
    FormVerifyUser,
    TCard,
    TLogo,
  },

  data: () => ({
    verified: false,
    user: {
      address: {
        country: "Brazil",
      } as Address,
    } as User,
    loading: false,
  }),

  computed: {
    dialog(): DialogConfig {
      return this.$store.state.dialog.config as DialogConfig;
    },
    form(): ResetableForm {
      return this.$refs.form as ResetableForm;
    },
  },

  methods: {
    async createUser() {
      try {
        this.loading = true;

        this.user.username = this.user.name.replace(/[\W_]+/g, "").toLowerCase();

        this.user.birth_date = this.formatDate(this.user.birth_date, "y-MM-dd", "dd/MM/y");

        const registered = await userCreate(this.user);

        if (registered) {
          await this.login();
        } else {
          throw new Error("Erro ao tentar realizar cadastro");
        }

        this.loading = false;
        this.$snackbar("Cadastro realizado com sucesso!", "success");

        this.$analytics.collect(
          TrackableEvent.Register,
          { params: { method: "password" } },
        );

        this.close();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          this.$snackbar(error.response?.data.message, "error");

          // Reseting birth date to visual format
          this.user.birth_date = this.formatDate(this.user.birth_date, "dd/MM/y", "y-MM-dd");

          this.form.reset();
          this.loading = false;
        } else {
          console.log(e);
        }
      }
    },
    formatDate(date: string, format: string, input_format = "y-MM-dd HH:mm:ss"): string {
      const parsed = DateTime.fromFormat(date, input_format);
      return parsed.toFormat(format);
    },
    onVerifyUser(userExists: boolean) {
      if (userExists) {
        this.$dialog.show("/recover");

        this.$snackbar("CPF já cadastrado em nossa base, recupere sua senha!", "error");
      }
      userExists ? this.$dialog.show("/recover") : (this.verified = true);

      /*
       * se o usuário já tiver cadastro, redirect pra recuperação de senha
       * do contrário, renderiza form dos dados do usuário
       */
    },
    async login() {
      const { data } = await getAccessToken(this.user.email, this.user.password);
      this.$store.commit("user/setToken", data);
      const user = (await getUserData()).data;
      this.$store.commit("user/setUser", user);
    },
    close() {
      this.$dialog.hide();
    },
  },
});
</script>
