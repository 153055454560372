import { AxiosRequestConfig } from "axios";
import store from "@/store";

export default function producer(config: AxiosRequestConfig): AxiosRequestConfig {
  const producer = store.state.producers.selected;

  if (producer.id !== "0") {
    config.headers["Producer-Id"] = producer.id;
  }

  return config;
}
