import { VuetifyThemeVariant } from "vuetify/types/services/theme";

const light: Partial<VuetifyThemeVariant> = {
  "primary": "#5187EE",
  "secondary": "#97BBFF",
  "tertiary": "#01416b",
  "dark-background": "#0E3862",
  "background": "#DAE2F0",
  "content-background": "#F8F8F8",
  "heading": "#fff",
  "body": "#D4D4D4",
};

export default { light };
