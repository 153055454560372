<template>
  <v-app>
    <snackbar />
    <the-dialog />
    <the-app-bar />
    <v-main>
      <router-view />
    </v-main>
    <the-cookie-dialog />
    <the-footer />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";

import Snackbar from "@/components/core/Snackbar.vue";
import TheDialog from "@/components/core/TheDialog.vue";
import TheAppBar from "@/components/core/TheAppBar.vue";
import TheFooter from "@/components/core/TheFooter.vue";
import TheCookieDialog from "@/components/core/TheCookieDialog.vue";
import { WhitelabelProducer } from "./types/Producer";
import { useHead } from "@unhead/vue";
import { useFavicon } from "@vueuse/core";

export default Vue.extend({
  name: "App",
  components: {
    Snackbar,
    TheAppBar,
    TheFooter,
    TheCookieDialog,
    TheDialog,
  },

  data: () => ({
    //
  }),

  computed: {
    producer(): WhitelabelProducer {
      return this.$store.state.producers.selected as WhitelabelProducer;
    },
  },

  watch: {
    producer: {
      immediate: true,
      handler(old: WhitelabelProducer, _new: WhitelabelProducer) {
        if (_new === undefined) return;

        this.handleProducer(_new);
      },
    },
  },

  mounted() {
    this.handleProducer(this.producer);
  },

  methods: {
    handleProducer(producer: WhitelabelProducer) {
      // Vuetify theme
      Object.keys(producer.theme.light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = producer.theme.light[i];
      });

      // Favicon
      const icon = useFavicon();

      if (producer.id !== "0") {
        icon.value = "https://storage.torcedordevantagens.com.br/ticket/bdabcc90-f3b2-11ef-8042-df79c9b6e72d/favicon.png";
      }

      // Meta tags
      useHead({
        title: producer.title,
        meta: [
          { name: "description", content: producer.footer },
          { name: "og:description", content: producer.footer },
        ],
      });

      console.log(this.$i18n);
      this.$i18n.mergeLocaleMessage("pt-br", producer.locale["pt-br"]);
    },
  },
});
</script>

<style>
#app {
  overflow-x: hidden;
}

body,
html {
  scroll-behavior: smooth;
}
</style>
