import axios from "axios";
import auth from "./interceptors/auth";
import producer from "./interceptors/producer";

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(auth);
api.interceptors.request.use(producer);

export default api;
