import { LocaleMessages } from "vue-i18n";

type Locale = "pt-br";

type LocaleConfiguration = "default" | "maracana";

const configuration: Record<LocaleConfiguration, Record<Locale, LocaleMessages>> = {
  default: {
    "pt-br": {
      entities: {
        ticket: "Ingresso | Ingressos",
        type: "Tipo de Ingresso",
      },
      actions: {
        ticket: {
          view: "Visualizar Ingressos",
          save: "Salvar Ingressos",
        },
      },
      menu: {
        tickets: "Meus Ingressos",
      },
      event: {
        buy: "Comprar Ingressos",
        opening: "Abertura dos Portões",
      },
      checkout: {
        steps: {
          choice: {
            short: "Escolha de Produtos",
            complete: "Escolha de Produtos",
          },
        },
      },
    },
  },
  maracana: {
    "pt-br": {
      entities: {
        ticket: "Produto | Produtos",
        type: "Produto",
      },
      actions: {
        ticket: {
          view: "Visualizar Produtos",
          save: "Salvar",
        },
      },
      menu: {
        tickets: "Meus Produtos",
      },
      event: {
        buy: "Comprar",
        opening: "Abertura do Club",
      },
      checkout: {
        steps: {
          choice: {
            short: "Escolha de Produtos",
            complete: "Escolha de Produtos",
          },
        },
      },
    },
  },
};

export { configuration };
