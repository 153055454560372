import api from "@/api";
import Sector from "@/types/Sector";
import Reservation from "@/types/Reservation";
import { AxiosResponse } from "axios";
import SeatWithTicketType from "@/types/Mapping/SeatWithTicketType";

interface Payload {
  event_id: string;
  token: string;
  sectors: Sector[];
  seats?: SeatWithTicketType[];
}

export default async function reserveTickets(data: Payload): Promise<Reservation> {
  const response: AxiosResponse<Reservation> = await api.post(`/api/user/reserva`, {
    ...data,
    seats: data.seats?.map((t: SeatWithTicketType) => t.seat),
  });

  return response.data;
}
