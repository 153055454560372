<template>
  <t-card max-width="600">
    <v-card-title>
      <span v-if="dialog.hasTitle">RECUPERAÇÃO DE SENHA</span>
      <v-spacer />
      <v-btn v-if="dialog.hasCloseButton" icon>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" class="my-4">
        <v-col cols="6">
          <t-logo stretch />
        </v-col>
      </v-row>
      <v-stepper v-model="step" elevation="0" style="background: transparent">
        <v-stepper-header style="box-shadow: none; border-bottom: 1px solid orange">
          <v-stepper-step v-for="step in steps" :key="step.id" :step="step.id" />
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="step in steps" :key="step.id" :step="step.id">
            <component
              :is="step.component"
              v-model="user"
              :token="token"
              :methods="methods"
              :selected-method="selectedMethod"
              @submit="validate"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </t-card>
</template>

<script lang="ts">
import Vue from "vue";

import User from "@/types/User";

import TCard from "@/components/core/TCard.vue";
import { DialogConfig } from "@/store/modules/dialog";
import { PasswordToken } from "@/api/requests/getPasswordRecoveryToken";
import TLogo from "@/components/core/TLogo.vue";
import FormGetRecoverCode from "@/components/User/FormGetRecoverCode.vue";
import FormValidateRecoverCode from "@/components/User/FormValidateRecoverCode.vue";
import FormRecoverPassword from "@/components/User/FormRecoverPassword.vue";
import FormSearchAccount from "@/components/User/FormSearchAccount.vue";
import RecoveryMethod from "@/types/RecoveryMethod";

export default Vue.extend({
  name: "PasswordRecover",

  components: {
    TCard,
    TLogo,
  },

  data: () => ({
    verified: false,
    user: {} as User,
    sent: false,
    step: 1,
    steps: [
      {
        id: 1,
        name: "Encontrar sua conta",
        component: FormSearchAccount,
      },
      {
        id: 2,
        name: "Escolher método de identificação",
        component: FormGetRecoverCode,
      },
      {
        id: 3,
        name: "Validar identificação",
        component: FormValidateRecoverCode,
      },
      {
        id: 4,
        name: "Escolher nova senha",
        component: FormRecoverPassword,
      },
    ],
    token: {} as PasswordToken,
    methods: [] as RecoveryMethod[],
    selectedMethod: {} as RecoveryMethod
  }),

  computed: {
    dialog(): DialogConfig {
      return this.$store.state.dialog.config;
    },
  },

  methods: {
    async validate($event: PasswordToken | RecoveryMethod | RecoveryMethod[]): Promise<void> {
      const steps: Record<number, () => boolean | Promise<boolean | void>> = {
        [1]: () => {
          this.methods = $event as RecoveryMethod[];
          return true;
        },
        [2]: () => {
          this.selectedMethod = $event as RecoveryMethod;
          return true;
        },
        [3]: () => {
          this.token = $event as PasswordToken;
          return true;
        },
        [4]: () => this.submit(),
      };

      const valid = !steps[this.step] || (await steps[this.step]());

      if (!valid) return;

      this.step++;
    },
    close() {
      this.$dialog.hide();
    },
    submit() {
      this.$snackbar("Senha alterada com sucesso!", "success");
      this.close();
      return true;
    },
  },
});
</script>
