<template>
  <div class="d-flex justify-center">
    <img
      alt="Logo Rodapé"
      :src="logo"
      v-bind="$attrs"
      style="width: 100%; cursor: pointer"
      transition="scale-transition"
      v-on="$listeners"
    >
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Logo from "@/assets/logo.svg";
import LogoDark from "@/assets/logo.dark.svg";
import { WhitelabelProducer } from "@/types/Producer";

export default Vue.extend({
  props: {
    dark: {
      default: false,
      type: Boolean,
    },
    stretch: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    logo() {
      const producer = this.$store.state.producers.selected as WhitelabelProducer;

      if (producer.id === "0") {
        return this.dark ? LogoDark : Logo;
      }

      if (this.dark) {
        return `https://storage.torcedordevantagens.com.br/ticket/${producer.id}/logo-footer-dark.svg`;
      }

      return `https://storage.torcedordevantagens.com.br/ticket/${producer.id}/logo-footer.svg`;
    },
  },
});
</script>
