import { MiddlewareContext } from ".";

/**
 * Searches for a slug in route params to change company theme
 *
 * @param context `MiddlewareContext`
 * @returns `void`
 */
export function company(context: MiddlewareContext) {
  let domain = window.location.hostname;

  if (domain.includes(".local")) {
    domain = domain.replace(".local", ".com.br");
  }

  context.store.dispatch("producers/setByDomain", domain);

  return context.next();
}
